<template>
  <div class="projects">
    <back-arrow to="/portfolio" />

    <div class="wrapper foreground">
      <section class="blend-white-bg regular-padding">
        <h1>Projects</h1>
        <p>Thank you for showing an interest in my work. Here I list some of my projects. In this portfolio I show a mixture of professional work I was allowed to disclose as well as my personal projects, such as large-scale mod(ification)s of games.</p>
        <p>As I'm still actively working on projects, I will try to update this page occasionally.</p>
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Mount & Blade: Warband mod - Full Invasion"
          when="~2010"
          description="<p>A well-known modification project of the game Mount & Blade: Warband, Full Invasion was actually also the first multiplayer mod released for this game.</p>
          <p>In the mod, a new way of playing was added, through the 'Invasion' game mode. In this mode, players face off against increasingly stronger (and more absurd) computer-controlled enemies. The enemies' weapons can be used against them though, so players end up riding to battle on tiny horses and goats, wielding crazy long swords.</p>
          <p>It has been one of the most played and most downloaded (over 150.000 downloads) multiplayer modifications of the game, as it is even now still played despite being released in 2010! Moreoverly, many large spin-offs projects using the same code, same concept has sprouted from this project, and Taleworlds even went as far as including it in their official expansion 'With Fire and Sword'. Other developers continued the concept and turned it into a series with Full Invasion 2.</p>"
          myRole="<p>I started this project editing .txt files before any official modding tools were released, in the beta of the game. Once actual mod tools came out, I learned how to create new game modes and coded the initial version in the Python-based language.</p>
          <p>Eventually, I met some people who enjoyed the mod and offered to help. One person well-known from the modding community was Fredelios, who became the main artist of the mod. Various other people created some scenes (maps) for the game, especially another person well-known on the M&B forums, Kazzan.</p>
          <p>I continued as a team lead then, doing the coding part and balancing the mod so that it would scale with the amount of players. We provided continuous updates for well over a year, until we decided to move onto different projects.</p>"
          reflection="<p>It was this project that introduced me to what it means to be a game developer, especially to be a game designer and team lead. It convinced me to study Game Design, and kept me in game development afterwards.</p>
          <p>During the project I learned that it is rather difficult to make a game work for 4 players, as well as 64 players. Scaling the amount of enemies to be 16x as much doesn't quite do it, as especially some tactics become much more effective in large groups. Ranged weaponry was quite over-tuned, as it became more and more effective the more players there were, meaning the enemies couldn't even come close.</p>
          <p>Another big issue was grieving players. Most servers shifted to disabling friendly fire, not to make the combat easier, but to avoid people hitting their own teammates on purpose. After these counter-measures, players found other ways to ruin the fun, by spamming votes to change the map continuously. I've learned the importance of anti-grieving measures now, as you unfortunately cannot expect people to play nice.</p>
          <p>Interested? <a href='https://www.moddb.com/mods/full-invasion'>Give it a try!</a></p>"
          :pictures="galleryFullInvasion"
          thumbnail="assets\Portfolio\Games\Full Invasion\thumbnail.jpg"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Divinity: Original Sin 2"
          when="2017-2018"
          description="<p>The critically acclaimed role-playing video game developed and published by Larian Studios. This game won PCGamer's game of the year award 2018, as well as many other rewards.</p>
          <p>I have been part of the team giving post-release support to <a href='https://divinity.game/'>Divinity: Original Sin 2</a>, as a Game Design intern.</p>"
          myRole="<p>As a Game Design intern, I was given different tasks, such as looking into Controller support, making a keyboard light up when casting a fireball as well as learning how to script quests.</p>
          <p>During the internship, I learned how to script quests (using their custom scripting language) and how to use their level editor.</p>
          <p>My time at Larian Studios took place just after the launch, during which there was a small period of time between Baldur's Gate 3 and D:OS 2 in which the studio was working on a DLC which turned into a small standalone game.</p>"
          reflection="<p>I felt very lucky to be selected for the internship, and the Lead Game Designer said there was tough competition. The applicants (me included) very tasked with writing a questline, keeping in mind all the different approaches a player could think of acquiring or (accidentally) completing the quest.</p>
          <p>Unfortunately, I also felt it would be more interesting to be at the company during development of D:OS 2 or Baldur's Gate, as the company had a bit of a lull in the development at that particular time.</p>"
          :pictures="galleryDivinity"
          thumbnail="assets\Portfolio\Games\Divinity Original Sin 2\dos2splash.jpg"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Drifting Skies"
          when="currently in development"
          description="<p>Following a cataclysmic event that shattered earth, small remnants of civilization with 17-18th century technology are trying to find a way to exist on the numerous floating islands.</p>
          <p>A recently started project I started with a friend, focused around airships. The first milestone of the game is fully simulated 3D airships as well as a world with floating islands, ever on the move and synchronized over multiplayer. Eventually, we will try to make 4X game out of this.</p>
          <p>Using modern technologies such as Machine Learning in Unity, we are trying to get the most out of Unity as well as making a unique and perhaps overly ambitious game.</p>"
          myRole="<p>As a two man project, we are both doing a bit of everything. My tasks thusfar have been to explore the Unity machine learning agents and create a prototype of that. Additionally, I have been using the new UIBuilder of Unity, creating modern UI with CSS-like styling. On top of that, I have written game backstory for the game world and started an excel sheet of the 4X tokens: resources, buildings, units, etc.</p>"
          thumbnail="assets\Portfolio\Games\Drifting Skies\thumbnail.png"
          :pictures="galleryDriftingSkies"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Game Jams"
          when="2019 - now"
          description="<p>As a hobby, I enjoy taking part in game jams. The last few years, I have been participating in Finland. I took part in Games Now! 2019, Finnish Global Game Jam 2020 and 2021. Check out the following links for prototypes:<br>
          <a href='https://jeroenb.itch.io/anomaly'>https://jeroenb.itch.io/anomaly</a><br>
          <a href='https://globalgamejam.org/2020/games/grime-repaire-3'>https://globalgamejam.org/2020/games/grime-repaire-3</a><br>
          <a href='https://globalgamejam.org/2021/games/sole-mates-5'>https://globalgamejam.org/2021/games/sole-mates-5</a></p>"
          myRole="<p>As many teams during Game Jams often need programmers, I usually at least do some programming. At the same time, I also try to do other roles, especially game design roles. In the Anomaly game (2019 Games Now!) I mostly designed the concept of the game, while in the other jams I used Unity for developing the game, as the only programmer.</p>"
          :pictures="galleryGameJams"
          thumbnail="assets\Portfolio\Games\Gamejams\anomaly.png"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="WarGame mobile game"
          when="2017-2018"
          description="<p>A prototype project when working at the company <a href='http://www.hypester.com/'>Hypester</a>.</p>
          <p>The game was a new take on the Match-3 type of game, that focused on player-versus-player. Two players saw the same 4-color board, on which they swiped with their finger for large combos. The better the combo, the more damage done to the opponent, as well as filling up special powers.</p>
          <p>This created a dynamic of finding the largest chain (which is easy) but also trying to avoid giving the opponent large chains and tactically charging up the special powers. Special powers included some high-damage projectile (aimed and launched using swipe gestures), a shield and changing up the board.</p>"
          myRole="<p>For this project, I was the solo developer and acquired experience in developing the classic Match 3 style games. I solo-developed smooth touch controls, including multiple gestures, as well as a work multiplayer functionality and other mobile-game standards, like logging in with Facebook.</p>"
          reflection="<p>While definitely a very good project to learn Unity better, and getting in touch with the potentially more profitable market of game development, I also realised my heart does not lie with mobile games, especially not Match-3 games that have been done over and over again.</p>"
          :pictures="galleryWargame"
          thumbnail="assets\Portfolio\Games\HexaClash\thumbnail.jpg"
        />
      </section>

      <section class="blend-white-bg regular-padding" style="margin-top: 60px;">
        <h1>Minor projects</h1>
        <p>I also had various minor projects, which I don't consider my main portfolio items, but could give some additional impressions of my interests and history.</p>
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Ascent from Akeron"
          when="2015"
          description="<p>A pilot project during the Game Design Minor in 2015/2016. This project was in cooperation with Submarine Channel, who were developing an interactive comic, to be found on <a href='https://ascentfromakeron.com/'>https://ascentfromakeron.com/</a>. During this project, I took the role of project manager and level designer. The project team had 6 highly motivated people working on it and was one of the projects that convinced me I wanted to pursue a career in game development.</p>"
          :pictures="galleryAkeron"
          thumbnail="assets\Portfolio\Games\Ascent From Akeron\Akeron.png"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Ardennes 1914"
          when="2016"
          description="<p>My graduation project for the Amsterdam University of Applied Sciences (HvA) in collaboration with <a href='https://www.m2h.nl/'>M2H</a>, well-known from their WW1 shooter Verdun. This project, set in the same time period and with the same idea behind it (gritty realism, as well as providing informational historic contexts), was a solo project and taught be how to use Unity. For the project, I had to make a isometric view 3D, a procedurally generated tile system and turn-based strategy game controls, all from scratch.</p>"
          :pictures="galleryArdennes"
          thumbnail="assets\Portfolio\Games\Ardennes (Verdun)\splash_screen.png"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Tractor Defense"
          when="2019, unfinished"
          description="<p>A project by me (programming, design) and a friend (artwork), intended to make us step through all the processes of making a game from scratch, to actually releasing it on Google Play and the iOS App Store. This is a very recent project and work in progress.</p>"
          :pictures="galleryTractorDefense"
          thumbnail="assets\Portfolio\Games\Space Invaders\mockup_wide_a_002.jpg"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="The Ship of Fellows"
          when="~2016-2018, cancelled"
          description="<p>The sequel to the succesful Full Invasion. I teamed up with the original co-developers of the previous mod, to create an even more ambitious co-op experience. This mod has not yet been released, but is rather close, and already far exceeds the scope of previous projects. This mod seeks to pitch ideally 12 players against waves of computer-controlled enemies, growing gradually stronger. The enemies are procedurally generated, to offer different challenge every new game. Players are offered a series of interesting choice, by choosing whether to upgrade their weaponry, their armour or their economy. Upgrading economy is a classic risk&reward strategy, as it pays out in the long run, but the trick is getting there...</p>"
          :pictures="galleryFellows"
          thumbnail="assets\Portfolio\Games\The Ship of Fellows\thumbnail.jpg"
        />
      </section>

      <section class="project regular-padding">
        <portfolio-item
          title="Other university projects"
          when="2015-2016"
          description="<p>During my Game Design studies, I also created various other small projects, including board games. Click on the thumbnail image to open a gallery with a few more projects!</p>"
          :pictures="galleryUniProjects"
          thumbnail="assets\Portfolio\Games\University Projects\thumbnail.jpg"
        />
      </section>
    </div>
  </div>
</template>

<script>
//import {database} from '../firebase'
//import {tumblrClient} from '../tumblr'
import TextWithGallery from '../../components/TextWithGallery.vue'
import PortfolioItem from '../../components/PortfolioItem.vue'
import BackArrow from '../../components/BackArrow.vue'

export default {
  name: 'Projects',
  components: {TextWithGallery, PortfolioItem, BackArrow},
  data () {
    return {
      // <img class="active" src="assets\Portfolio\Games\Full Invasion\gameplay1.jpg"><p class="label active">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\gameplay2.jpg"><p class="label">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\gameplay4.jpg"><p class="label">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\gameplay6.jpg"><p class="label">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\gameplay8.jpg"><p class="label">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\modrank.jpg" alt="Download count"><p class="label">This mod and derived mods have made it to the top 100 of ModDB's vast amount of mods.</p>
      // <img src="assets\Portfolio\Games\Full Invasion\moddownloads.jpg">
      // <img src="assets\Portfolio\Games\Full Invasion\download counts.png"><p class="label">The mod could be downloaded from multiple location - this one alone has near 50k downloads.</p>
      galleryFullInvasion: [
        {
          src: "https://www.youtube.com/embed/-mQXSHvNW4A",
          youtube: true,
          text: "Show case of gameplay by YouTuber Tokshen."
        },
        // <iframe width="560" height="315" src="https://www.youtube.com/embed/-mQXSHvNW4A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        {
          src: "assets/Portfolio/Games/Full Invasion/gameplay1.jpg",
          text: "Fending of potato-sack carrying zombies."
        },
        {
          src: "assets/Portfolio/Games/Full Invasion/gameplay2.jpg",
          text: "Heavy armour against a begin-of-game enemy. The game starts easy."
        },
        {
          src: "assets/Portfolio/Games/Full Invasion/gameplay4.jpg",
          text: "Claustrophobic, torch-lit tunnels, about to be overrun."
        },
        {
          src: "assets/Portfolio/Games/Full Invasion/gameplay6.jpg",
          text: "Defending the bridge with muskets on top of make-shift barricades."
        },
        {
          src: "assets/Portfolio/Games/Full Invasion/gameplay8.jpg",
          text: "The hordes grow, as do the musket smoke clouds."
        },
        {
          src: "assets/Portfolio/Games/Full Invasion/modrank.jpg",
          text: "The mod became hugely popular."
        },
        // {
        //   src: "assets/Portfolio/Games/Full Invasion/moddownloads.jpg",
        //   text: ""
        // },
        // {
        //   src: "assets/Portfolio/Games/Full Invasion/download counts.png",
        //   text: "The mod could be downloaded from multiple location - this one alone has near 50k downloads."
        // },

      ],
      galleryGameJams: [
        {
          src: "assets/Portfolio/Games/Gamejams/solemate.jpg",
          text: "Latest Gamejam submission. SoleMate (Tinder for socks meme game)"
        },
        {
          src: "assets/Portfolio/Games/Gamejams/grimrepaire.png",
          text: "Grim Repaire OY. A game about fixing toilets as the Grim Reaper."
        },
        {
          src: "assets/Portfolio/Games/Gamejams/solemate.jpg",
          text: "Latest Gamejam submission. SoleMate (Tinder for socks meme game)"
        },
      ],
      galleryDriftingSkies: [
        {
          src: "assets/Portfolio/Games/Drifting Skies/ship_sim.png",
          text: "Fully simulated 3D ship with sails, trimming, damage effects, etc."
        },
        {
          src: "assets/Portfolio/Games/Drifting Skies/movie_009.mp4",
          movie: true,
          text: "Globe with fluid simulation, islands drift around."
        },
        {
          src: "assets/Portfolio/Games/Drifting Skies/machine_learning.jpg",
          text: "Early MLA (Machine Learning Agents) prototype, getting cannons to shoot down airships."
        },
      ],
      galleryDivinity: [
        {
          src: "assets/Portfolio/Games/Divinity Original Sin 2/dos2splash.jpg",
          text: ""
        },
        {
          src: "assets/Portfolio/Games/Divinity Original Sin 2/dos2gameplay.jpg",
          text: ""
        },
      ],
      // <img class="active" src="assets\Portfolio\Games\Ascent From Akeron\Akeron.png">
      // <img src="assets\Portfolio\Games\Ascent From Akeron\gameplay1.png"><p class="label">Storytelling.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\gameplay2.png"><p class="label">Gameplay and tutorial.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\gameplay3.png"><p class="label">Gameplay.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\gameplay4.png"><p class="label">Gameplay - bossfight.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\builder1.png"><p class="label">Unity level designing.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\webcomic1.png"><p class="label">Webcomic.</p>
      // <img src="assets\Portfolio\Games\Ascent From Akeron\webcomic2.png"><p class="label">Webcomic.</p>
      galleryAkeron: [
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/Akeron.png",
          text: ""
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/gameplay1.png",
          text: "Storytelling."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/gameplay2.png",
          text: "Gameplay and tutorial."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/gameplay3.png",
          text: "Gameplay."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/gameplay4.png",
          text: "Gameplay - bossfight."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/builder1.png",
          text: "Unity level designing."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/webcomic1.png",
          text: "Webcomic."
        },
        {
          src: "assets/Portfolio/Games/Ascent From Akeron/webcomic2.png",
          text: "Webcomic."
        },
      ],
      // <img class="active" src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay1.png"><p class="active label">Placeholder introduction menu.</p>
      // <img src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay2.png"><p class="label">Gameplay and tutorial.</p>
      // <img src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay3.png"><p class="label">Fog of war is implemented</p>
      // <img src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay4.png"><p class="label">Targetting options are shown.</p>
      // <img src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay5.png"><p class="label">Artillery trajectory.</p>
      // <img src="assets\Portfolio\Games\Ardennes (Verdun)\gameplay6.png"><p class="label">Artillery explosion.</p>
      galleryArdennes: [
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay1.png",
          text: "Placeholder introduction menu."
        },
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay2.png",
          text: "Gameplay and tutorial."
        },
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay3.png",
          text: "Fog of war is implemented"
        },
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay4.png",
          text: "Targetting options are shown."
        },
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay5.png",
          text: "Artillery trajectory."
        },
        {
          src: "assets/Portfolio/Games/Ardennes (Verdun)/gameplay6.png",
          text: "Artillery explosion."
        },
      ],
      galleryTractorDefense: [
        {
          src: "assets/Portfolio/Games/Space Invaders/mockup_wide_a_002.jpg",
          text: "Concept."
        },
        {
          src: "assets/Portfolio/Games/Space Invaders/gameplay.png",
          text: "Gameplay."
        },
      ],
      galleryFellows: [
        {
          src: "assets/Portfolio/Games/The Ship of Fellows/fellowz_menu.jpg",
          text: "Defending a ladder."
        },
        {
          src: "assets/Portfolio/Games/The Ship of Fellows/gameplay1.jpg",
          text: "Looting enemies - using their magic against them."
        },
        {
          src: "assets/Portfolio/Games/The Ship of Fellows/gameplay2.jpg",
          text: "Artwork and armour display."
        },
        {
          src: "assets/Portfolio/Games/The Ship of Fellows/gameplay3.jpg",
          text: "Level lay-out."
        },
        {
          src: "assets/Portfolio/Games/The Ship of Fellows/gameplay4.jpg",
          text: "Enemies grow stronger - but so does the players' base."
        },
      ],
      galleryWargame: [
        {
          src: "assets/Portfolio/Games/HexaClash/preview.jpg",
          text: "iOS deployment."
        },
        {
          src: "assets/Portfolio/Games/HexaClash/gameplay1.png",
          text: "Gameplay."
        },
        {
          src: "assets/Portfolio/Games/HexaClash/gameplay2.png",
          text: "Gameplay."
        },
        {
          src: "assets/Portfolio/Games/HexaClash/gameplay3.png",
          text: "Gameplay."
        },
        {
          src: "assets/Portfolio/Games/HexaClash/gameplay4.png",
          text: "Gameplay."
        },
      ],
      galleryUniProjects: [
        {
          src: "assets/Portfolio/Games/University Projects/bomberman_process.jpg",
          text: "Designing a bomberman boardgame, by paper prototyping."
        },
        {
          src: "assets/Portfolio/Games/University Projects/bomberman.jpg",
          text: "Recreating a polished version of the bomberman board game, by laser cutting wood."
        },
        {
          src: "assets/Portfolio/Games/University Projects/polytheism.jpg",
          text: "University application submission - board game design in Tabletop Simulator."
        },
        {
          src: "assets/Portfolio/Games/University Projects/beegame.png",
          text: "Thesis project - created a small game to test the perceived difference in difficulty, depending on music choice."
        },
      ],
    }
  },
  methods: {
  },
  mounted () {
  },
}
</script>


<style lang="scss">
  @import '@/scss/_variables.scss';

  .projects {
    display: flex;

    section {
      margin-top: 15px;
      border-radius: 7.5px;

      &.project {
        background: white;
      }
    }
  }
</style>
