<template>
  <div class="portfolio-item">
    <div class="title">
      <h1>{{title}}</h1>
      <span>({{when}})</span>
    </div>

    <div class="content">
      <div class="text-box">
        <section v-if="description && description.length">
          <h2>Description</h2>
          <span v-html="description" />
        </section>

        <section v-if="myRole && myRole.length">
          <h2>My role & tasks</h2>
          <span v-html="myRole" />
        </section>

        <section v-if="teamInfo && teamInfo.length">
          <h2>The team</h2>
          <span v-html="teamInfo" />
        </section>

        <section v-if="reflection && reflection.length">
          <h2>Reflection</h2>
          <span v-html="reflection" />
        </section>
      </div>

      <div class="gallery">
        <div class="thumbnail" v-if="thumbnail">
          <img @click="openGallery" :src="thumbnail" alt="Open Gallery">
          <span @click="openGallery">Click to see media...</span>  
        </div>
      </div>
    </div>

    <div class="gallery-popup" v-if="galleryActive">
      <div class="gallery-content">
        <template v-for="(image, i) in pictures">
          <div :class="i === activeIndex ? 'active gallery-item' : 'gallery-item'" v-bind:key="i">
            <span class="vertical-align-helper" />
            <iframe v-if="image.youtube"
            :src="image.src">
            </iframe>
            <video v-else-if="image.movie" controls>
              <source :src="image.src" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <img 
              v-else
              :src="image.src"
            />
            <p class="description" v-if="image.text && image.text.length">
              {{image.text}}
            </p>
          </div>
        </template>
        <button @click="prevGalleryItem" class="gallery-previous">
          <svg xmlns="http://www.w3.org/2000/svg" width="49.42" height="60" viewBox="0 0 49.42 60">
            <g id="Back" transform="translate(-65.5 -584.25)">
              <path id="Polygon_1" data-name="Polygon 1" d="M30,0,60,35.467H0Z" transform="translate(65.5 644.25) rotate(-90)" fill="#dedede"/>
              <rect id="Floor" width="17.482" height="26.223" transform="translate(97.438 601.139)" fill="#dedede"/>
            </g>
          </svg>

        </button>

        <button @click="nextGalleryItem" class="gallery-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="49.42" height="60" viewBox="0 0 49.42 60">
            <g id="Next" transform="translate(114.92 644.25) rotate(180)">
              <path id="Polygon_1" data-name="Polygon 1" d="M30,0,60,35.467H0Z" transform="translate(65.5 644.25) rotate(-90)" fill="#dedede"/>
              <rect id="Floor" width="17.482" height="26.223" transform="translate(97.438 601.139)" fill="#dedede"/>
            </g>
          </svg>
        </button>
      </div>

      <button @click="closeGallery"  class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="53.129" height="53.129" viewBox="0 0 53.129 53.129">
          <g id="Cross" transform="translate(-1412.467 -241.467)">
            <rect id="Rectangle_90" data-name="Rectangle 90" width="15.545" height="59.591" transform="translate(1454.604 241.467) rotate(45)" fill="#dedede"/>
            <rect id="Rectangle_91" data-name="Rectangle 91" width="15.545" height="59.591" transform="translate(1412.467 252.459) rotate(-45)" fill="#dedede"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'portfolio-item',
  props: {
    title: {
      type: [String, Boolean], //can be false, to hide
      default: ""
    },
    when: {
      type: [String, Boolean],
      default: ""
    },
    description: {
      type: [String, Boolean],
      default: ""
    },
    myRole: {
      type: [String, Boolean],
      default: ""
    },
    teamInfo: {
      type: [String, Boolean],
      default: ""
    },
    reflection: {
      type: [String, Boolean],
      default: ""
    },
    //Objects: {src: String, text: String}
    pictures: {
      type: Array,
      default: () => [],
    },
    thumbnail: {
      type: [String, Boolean],
      default: false
    },
  },

  data () {
    return {
      galleryActive: false,
      activeIndex: 0
    }
  },

  methods: {
    openGallery() {
      this.galleryActive = true;
    },

    closeGallery() {
      this.galleryActive = false;
    },

    nextGalleryItem() {
      this.activeIndex++;

      if (this.activeIndex > this.pictures.length-1) {
        this.activeIndex = 0;
      }
    },

    prevGalleryItem() {
      this.activeIndex--;

      if (this.activeIndex < 0) {
        this.activeIndex = this.pictures.length-1;
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@/scss/_variables.scss';

  .portfolio-item {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;

      span {
        margin-left: 30px;
      }
    }

    .content {
      display: flex;

      .thumbnail {
        margin-left: 15px;
        position: relative;
        cursor: pointer;

        img {
          max-width: 300px;
          // max-height: 100%;
          // width: 100%;
        }

        span {
          width: calc(100% - 30px);
          position: absolute;
          left: 0;
          bottom: 0;
          background: rgba(0,0,0,0.75);
          padding: 15px;
          color: white;
        }
      }
    }

    .gallery-popup {
      position: fixed;
      z-index: 999;
      width: calc(100vw - 20%);
      top: 10%;
      bottom: 10%;
      left: 10%;
      right: 10%;

      button {
        position: absolute;
        cursor: pointer;
        background: none;
        border: none;
        color: white;
        font-size: 2rem;
      }

      button.close-button {
        top: -15px;
        right: -15px;
        // position: fixed;
        // top: calc(10% + 5px);
        // right: 10%;
      }

      .gallery-content {
        height: 100%;
        position: relative;

        button.gallery-previous {
          left: -20px;
          top: 47%;
        }

        button.gallery-next {
          right: -20px;
          top: 47%;
        }

        .gallery-item {
          position: relative;
          height: 100%;
          display: none;
          background: black;
          text-align: center;

          &.active {
            display: block;
          }

          iframe {
            max-width: 100%;
            width: 100%;
            width: -webkit-fill-available;
            height: 100%;
          }
          
          video {
            max-width: 100%;
            height: calc(100% - 40px);
          }

          img {
            max-width: 100%;
            height: 100%;
          }

          .description {
            position: absolute;
            bottom: 15px;
            max-width: calc(100% - 60px);
            padding: 15px;
            margin: 0 15px;
            background: rgba(255, 255, 255, .75);
          }
        }
      }
    }
  }
</style>
