<template>
    <router-link :to="to" class="back-arrow animate__animated animate__fadeInLeft">
        <svg xmlns="http://www.w3.org/2000/svg" width="251.5" height="90.75" viewBox="0 0 251.5 90.75">
          <g id="Back_arrow" data-name="Back arrow" transform="translate(-65.5 -582)">
            <path id="Polygon_1" data-name="Polygon 1" d="M44.25,0,88.5,52.748H0Z" transform="translate(65.5 672.75) rotate(-90)" fill="#a16218"/>
            <rect id="Floor" width="26" height="39" transform="translate(113 609)" fill="#a16218"/>
            <rect id="Floor-2" data-name="Floor" width="26" height="39" transform="translate(291 609)" fill="#a16218"/>
            <text id="BACK" transform="translate(143 648)" fill="#a16218" font-size="140" font-family="Game-Over, Game Over"><tspan x="0" y="0">BACK</tspan></text>
          </g>
        </svg>
    </router-link>
</template>

<script>
export default {
  name: 'back-arrow',
  props: {
    to: {
        type: String,
        default: "/"
    }
  },
  data () {
    return {
    }
  },

  methods: {
  },
}
</script>

<style lang="scss">
    @import '@/scss/_variables.scss';
    .back-arrow {
      z-index: 1;
      position: fixed;
      top: 40%;
      left: scale_width(30);
      width: scale_width(180);
      min-width: 90px;

      svg {
        width: 100%;
      }

      @media (max-width: 800px) {
        top: 0;
        z-index: 99;

      }
    }
</style>
